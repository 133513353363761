import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import {
	Badge,
	Button,
	ButtonGroup,
	Col,
	Container,
	Form,
	ListGroup,
	Modal,
	OverlayTrigger,
	Row,
	Spinner,
	Toast,
	ToastContainer,
	Tooltip,
} from 'react-bootstrap'

function FormDiff() {
	const [diff, setDiff] = useState()
	const [left, setLeft] = useState('')
	const [loadingCompare, setLoadingCompare] = useState(false)
	const [loadingSave, setLoadingSave] = useState(false)
	const [right, setRight] = useState('')
	const [showCopyBuyOrderToast, setShowCopyBuyOrderToast] = useState(false)
	const [showCopyShortUrlToast, setShowCopyShortUrlToast] = useState(false)
	const [showDiffModal, setShowDiffModal] = useState(false)

	const handleCloseDiffModal = () => setShowDiffModal(false)
	const handleOpenDiffModal = () => setShowDiffModal(true)

	const shortId = useParams().id

	useEffect(() => {
		if (!shortId) {
			return
		}

		fetch('/api/share/get', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				id: shortId,
			}),
		})
			// Parse the response to JSON.
			.then((response) => response.json())
			.then((data) => {
				setLeft(data.result.left)
				setRight(data.result.right)
			})
			.catch((error) => {
				// TODO: Gracefully handle this thing in case the backend send back an error.
				console.error('Could not save fitting: ', error)
			})
	}, [shortId])

	const handleShareButton = useCallback(
		(event) => {
			// Prevent all default button actions since we're overwriting them.
			event.preventDefault()

			// Start showing the loading icon.
			setLoadingSave(true)

			fetch('/api/share', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					left: left,
					right: right,
				}),
			})
				// Parse the response to JSON.
				.then((response) => response.json())
				.then((data) => {
					setLoadingSave(false)

					// Access the clipboard API to write the buy order to clipboard.
					navigator.clipboard
						.writeText(`${window.location.protocol}//${window.location.host}/share/${data.id}`)
						.then(() => {
							setShowCopyShortUrlToast(true)
							handleCloseDiffModal()
						})
						.catch((error) => {
							// TODO: Gracefully handle this thing if it fails due to browser support or something.
							console.error('Could not save fitting: ', error)
						})
				})
				.catch((error) => {
					// TODO: Gracefully handle this thing in case the backend send back an error.
					console.error('Could not save fitting: ', error)
				})
		},
		[left, right]
	)

	const handleCompareButton = useCallback(
		(event) => {
			// Prevent all default button actions since we're overwriting them.
			event.preventDefault()

			// Start showing the loading icon.
			setLoadingCompare(true)

			// Use the native fetch API to send a request to the backend.
			fetch('/api/compare', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					left: left,
					right: right,
				}),
			})
				// Parse the response to JSON.
				.then((response) => response.json())
				// Use the JSON to retrieve the 'diff' data from the backend.
				.then((data) => {
					// Write the 'diff' data to the application state.
					setDiff(data.diff)
					// Write the 'loading' state to the application state.
					setLoadingCompare(false)
					// Show the modal with the buy order information in case there if a 'diff'.
					handleOpenDiffModal()
				})
				.catch((error) => {
					// TODO: Gracefully handle this thing in case the backend send back an error.
					console.error('Could not compare fitting: ', error)
				})
		},
		[left, right]
	)

	const handleBuyOrderButton = useCallback(() => {
		const buyOrder = diff.items
			// Filter the items that you don't have enough of.
			.filter((item) => item.amount < item.requiredAmount)
			// Build a list of items according to the import buy order format.
			.map((item) => `${item.name}\t${item.requiredAmount - item.amount}`)
			// Create a string with newlines.
			.join('\n')

		// Access the clipboard API to write the buy order to clipboard.
		navigator.clipboard
			.writeText(buyOrder)
			.then(() => {
				setShowCopyBuyOrderToast(true)
				handleCloseDiffModal()
			})
			.catch((error) => {
				// TODO: Gracefully handle this thing if it fails due to browser support or something.
				console.error('Could not copy text: ', error)
			})
	}, [diff])

	const requiredAmountFilter = (item) => item.amount < item.requiredAmount

	const buyOrderTooltip = (
		<Tooltip id="tooltip">Export the missing items to a buy order.</Tooltip>
	)
	const shareTooltip = (
		<Tooltip id="tooltip">Save your items to share it with others.</Tooltip>
	)

	return (
		<>
			<Container>
				<Row className="mt-3">
					<Col>
						<p>
							A tool designed to compare fittings from yourself
							against the alliance or corporation fittings. It
							uses Janice and you can compare any kind of accepted
							format that Janice accepts with each other.
						</p>
					</Col>
				</Row>
			</Container>
			<Modal
				size={'lg'}
				show={showDiffModal}
				onHide={handleCloseDiffModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>Missing items</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-0">
					{diff && (
						<>
							{diff.items.filter(requiredAmountFilter).length ===
								0 && (
								<p className="mb-0 p-2 ps-3 pe-3">
									No difference in items detected.
								</p>
							)}
							<ListGroup variant="flush">
								{diff.items
									.sort((a, b) => (a.name > b.name ? 1 : -1))
									.filter(requiredAmountFilter)
									.map((item, i) => {
										let bg = 'light'
										if (item.requiredAmount === 0) {
											bg = 'secondary'
										} else if (
											item.amount >= item.requiredAmount
										) {
											bg = 'success'
										} else if (item.amount === 0) {
											bg = 'danger'
										} else {
											bg = 'warning'
										}

										return (
											<ListGroup.Item key={i}>
												<img
													src={`https://images.evetech.net/types/${item.id}/icon?size=32`}
													alt={item.name}
													title={item.name}
												/>{' '}
												<span>
													{item.name}{' '}
													<Badge bg={bg}>
														{item.amount}/
														{item.requiredAmount}
													</Badge>
												</span>
											</ListGroup.Item>
										)
									})}
							</ListGroup>
						</>
					)}
				</Modal.Body>
				<Modal.Footer>
					<ButtonGroup>
						<OverlayTrigger
							placement="bottom"
							overlay={buyOrderTooltip}
						>
							<Button
								variant="primary"
								onClick={handleBuyOrderButton}
								disabled={
									diff &&
									diff.items &&
									diff.items.filter(requiredAmountFilter)
										.length === 0
								}
							>
								Export
							</Button>
						</OverlayTrigger>
						<OverlayTrigger
							placement="bottom"
							overlay={shareTooltip}
						>
							<Button
								variant="primary"
								type="submit"
								disabled={
									diff &&
									diff.items &&
									diff.items.filter(requiredAmountFilter)
										.length === 0
								}
								onClick={handleShareButton}
							>
								{loadingSave && (
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
								)}{' '}
								Share
							</Button>
						</OverlayTrigger>
					</ButtonGroup>
					<Button variant="secondary" onClick={handleCloseDiffModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
			<ToastContainer className="p-3" position="top-end">
				<Toast
					show={showCopyBuyOrderToast}
					onClose={() => setShowCopyBuyOrderToast(false)}
					delay={5000}
					autohide
				>
					<Toast.Header>
						<img
							src="https://images.evetech.net/alliances/1727758877/logo"
							width="16"
							height="16"
							className="rounded me-2"
							alt=""
						/>
						<strong className="me-auto">Buy Order</strong>
					</Toast.Header>
					<Toast.Body>
						The buy order is copied to your clipboard.
					</Toast.Body>
				</Toast>
			</ToastContainer>
			<ToastContainer className="p-3" position="top-end">
				<Toast
					show={showCopyShortUrlToast}
					onClose={() => setShowCopyShortUrlToast(false)}
					delay={5000}
					autohide
				>
					<Toast.Header>
						<img
							src="https://images.evetech.net/alliances/1727758877/logo"
							width="16"
							height="16"
							className="rounded me-2"
							alt=""
						/>
						<strong className="me-auto">Share</strong>
					</Toast.Header>
					<Toast.Body>
						The short url is copied to your clipboard. The link will
						expire in 7 days.
					</Toast.Body>
				</Toast>
			</ToastContainer>
			<Container>
				<Form>
					<Row>
						<Form.Group
							as={Col}
							md={6}
							className="mb-3"
							controlId="formYourFitting"
						>
							<Form.Label className="fw-bolder">
								Your Fitting
							</Form.Label>
							<Form.Control
								as="textarea"
								style={{ height: 300 }}
								value={left}
								onChange={(e) => setLeft(e.target.value)}
							/>
							<Form.Text className="text-muted">
								This is your fitting exported from, for example,
								the fitting or multibuy window.
							</Form.Text>
						</Form.Group>
						<Form.Group
							as={Col}
							md={6}
							className="mb-3"
							controlId="formAllianceCorporationFitting"
						>
							<Form.Label className="fw-bolder">
								Alliance/Corporation Fitting
							</Form.Label>
							<Form.Control
								as="textarea"
								style={{ height: 300 }}
								value={right}
								onChange={(e) => setRight(e.target.value)}
							/>
							<Form.Text className="text-muted">
								This is corporation fitting exported from, for
								example, the fitting or multibuy window.
							</Form.Text>
						</Form.Group>
					</Row>
					<Row>
						<Col>
							<Button
								className="mb-2"
								variant="primary"
								type="submit"
								disabled={!left || !right || left === right}
								onClick={handleCompareButton}
							>
								{loadingCompare && (
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
								)}{' '}
								Compare
							</Button>
						</Col>
					</Row>
				</Form>
			</Container>
		</>
	)
}

export default FormDiff
